<template>
  <div class="item">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'MyOrder' }"
        >我的订单</el-breadcrumb-item
      >
      <el-breadcrumb-item>售后/退款</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-row class="content">
      <div class="titBox">
        <TitleHeader msg="售后/退款" :noBorder="true"></TitleHeader>
      </div>
      <div class="wrapper">
        <el-row>
          <Nav :obj="obj" @clickIndex="changePropsIndex"></Nav>
        </el-row>
        <!-- 我的订单 订单详情 -->
        <el-row>
          <ul class="ulBox">
            <li>订单ID</li>
            <li>订单商品</li>
            <li>状态</li>
            <li>下单时间</li>
              <li>操作</li>
          </ul>
        </el-row>
        <BackDetail
          v-for="(item, index) in refundlist"
          :refundList="item"
          :key="index"
          :indexNum="index"
          :len="refundlist.length"
        ></BackDetail>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-row>
  </div>
</template>


<script>
import { RefundList } from "api/order.js";
const TitleHeader = () => import("components/common/TitleHeader.vue");
const Nav = () => import("components/common/Nav.vue");
const BackDetail = () => import("components/order/BackDetail.vue");
export default {
  name: "AfterRefund",
  data() {
    return {
       pagesize:10,
      currentPage: 1,
      total: 0,
      refundlist: "",
      //导航栏信息
      obj: {
        content: [
          {
            type: 0,
            name: "全部",
          },
          {
            type: 1,
            name: "待审核",
          },
          {
            type: 2,
            name: "处理中",
          },
          {
            type: 3,
            name: "处理完成",
          },
        ],
        currentIndex: 0,
        statusType:''  //筛选状态    售后[1]   申请处理中[4,5,6,7]     申请记录[2,3]     （1：待审核，2：已通过，3：不通过，4受理中，5受理完毕，6退货完成，7撤销申请）
      },
    };
  },
  methods: {
    changePropsIndex(params) {
       // 显示loading
      this.$loading({ fullscreen: true ,background:'#ffffff45'})
      this.obj.currentIndex = params;
      switch(Number(params)){
        case 0:
            this.statusType='';
          break;
        case 1:
            this.statusType="1";
        break;
        case 2:
          this.statusType="4,5,6";
        break;
        case 3:
          this.statusType="2,3,7";
        break;
        default:
          this.statusType="";
          break;
      }
       let datas=''
            //  状态（1：待审核，2：已通过，3：不通过，4受理中，5已受理，6退货完成，7撤销申请）
      if(params===4){
         datas={
              orderId:this.$route.query.ids
            }
      }else{
          datas={
                  status4DTO: this.statusType,
                    size:this.pagesize,
                  current:1,
                  // current: this.currentPage,
                };
      }
        RefundList(datas).then((res) => {
                this.refundlist = res.data.data.records;
                // 初始获取所有数据数量
              this.total = res.data.data.total;
               // 关闭loading
               this.$loading().close();
            });
    },
    getRefundList() {
      let datas=''
      if(this.$route.query.ids){
        // 有当前售后订单
         this.obj.currentIndex =4;
            this.obj.content.push({
                type:4,
                name: "当前售后单",
            })
            datas={
              orderId:this.$route.query.ids
            }
      }else{
        // 走整个售后
             datas={
              
            }
      }
       // 显示loading
    this.$loading({ fullscreen: true ,background:'#ffffff45'})
      //  获取我的售后订单列表
      RefundList(datas).then((res) => {
        this.refundlist = res.data.data.records;
        // 初始获取所有数据数量
        this.total = res.data.data.total;
         // 关闭loading
          this.$loading().close();
      });
    },
    handleSizeChange(val) {
      this.pagesize=val;
      let datas='';
      // 传每页显示数据
       datas={
          current: this.currentPage,
          size:  this.pagesize,
        status: this.statusType,
      };
      RefundList(datas).then((res) => {
        this.refundlist = res.data.data.records;
         // 初始获取所有数据数量
        this.total = res.data.data.total;
         // 关闭loading
          this.$loading().close();
      });
    },
    handleCurrentChange(val) {
       this.currentPage=val
       let datas='';
      // 传当前页数
      datas = {
          size:this.pagesize,
          current: this.currentPage,
          status4DTO: this.statusType,
      };
      RefundList(datas).then((res) => {
        this.refundlist = res.data.data.records;
         // 初始获取所有数据数量
        this.total = res.data.data.total;
         // 关闭loading
          this.$loading().close();
      });
    },
  },
  created() {
   
    this.getRefundList();
    if (this.$route.params.type) {
      this.obj.currentIndex = Number(this.$route.params.type);
    }
  },
  components: {
    TitleHeader,
    Nav,
    BackDetail,
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  padding-bottom: 20px;
  padding-top: 20px;
  color: #666666;
  font-size: 14px;
  line-height: 18px;
}
.item {
  .content {
    min-height: 500px;
    background: #fff;
    // padding: 10px;
    .titBox{
      // padding: 20px;
    }
    .wrapper {
      margin-top:20px;
      float: left;
      // padding: 0 26px;
      // width: calc(100% - 52px);
       width:100%;
      .ulBox {
        background: #f6f6f6;
        height: 38px;
        line-height: 30px;
        li {
          float: left;
          text-align: center;
          color: #666666;
          width: 150px;
          line-height: 38px;
          font-size: 12px;
        }
        li:nth-child(2) {
          width: calc(100% - 600px);
        }
      }
    }
  }
}
.Detailitem:last-child .main{
    border: 1px solid #e9eaec;
}
.el-pagination {
  padding-top: 20px;
  text-align: right;
}
</style>
<style lang="less">
.item {
  .nav {
    height: 20px;
    margin-bottom: 20px;
  }
}
.el-pager li.active {
  color: #ff3333;
}
.el-pager li:hover {
  color: #ff3333;
}
</style>
